import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import './App.css';

const socket = io('https://tinko.org'); // HTTPSに変更

function App() {
  const [jump, setJump] = useState(false);
  const [userCount, setUserCount] = useState(0);
  const [jumpCount, setJumpCount] = useState(0);
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    socket.on('jump', () => {
      setJump(true);
      setShowMessage(true);
      setTimeout(() => {
        setJump(false);
        setShowMessage(false);
      }, 1000); // 1秒後にジャンプを戻す
    });

    socket.on('updateUserCount', (count) => {
      setUserCount(count);
    });

    socket.on('updateJumpCount', (count) => {
      setJumpCount(count);
    });

    // Cleanup on component unmount
    return () => {
      socket.off('jump');
      socket.off('updateUserCount');
      socket.off('updateJumpCount');
    };
  }, []);

  const handleClick = () => {
    socket.emit('jump');
    setJump(true);
    setShowMessage(true);
    setTimeout(() => {
      setJump(false);
      setShowMessage(false);
    }, 1000);
  };

  return (
    <div className="Tinpoppo">
      <div className="header">
        <div>
          Current online users: {userCount}
        </div>
        <div>
          Jump count: {jumpCount}
        </div>
      </div>
      <div className={`face ${jump ? 'jump' : ''}`} onClick={handleClick}>
        {showMessage ? (
          <pre className="ascii-art">
            {"　 ∧　∧\n   (*’ω’*) 　ちんぽっぽ\n　 (　　)\n　　v v\n　　　　　　ぼいんっ\n　　川\n ( (　　) )"}
          </pre>
        ) : (
          <pre className="ascii-art">
            {"　 ∧　∧\n   (*’ω’*) 　ちんぽっぽ\n　 (　　)\n　　v v"}
          </pre>
        )}
      </div>
    </div>
  );
}

export default App;

